.login-page .row {
  margin-right: 0px !important;
  margin-left: 0px !important;
  height: 100vh;
}

.login-page .login-area {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login-page .login-area .login-form {
  padding: 50px 70px;
  border-radius: 7px;
  background: #ffffff;
  box-shadow: 0px 7px 18px #888890;
}

.login-page .login-area .login-form .login-title {
  color: #000000;
  text-align:center;
  font-size: 28px;
}

.login-page .login-area .login-form .validator-form .login-btn {
  width: 100%; 
  background-color: #282a37 !important;
  padding: 7px !important;
}

.login-page .login-area .login-page-logo {
  width: 70%;
  padding: 15px 20px;
  border-radius: 20px;
  border: 2px solid #1e1e2f;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
}

/* ========== Responsive =========== */


@media screen and (max-width: 425) {
  .login-page .login-area {
    margin-top: 15% !important;
  }
}