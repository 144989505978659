
/* ========== Custom Styles Token-Details =========== */

.view-data .view-data-body .view-data-row .wallet-address .view-data-title {
  font-size: 16px !important;
}

@media (max-width: 1024px) {
  .view-data .view-data-body .view-data-row p.wallet-address {
    font-size: 10px;
  }
}