.content .edit-add .copy-address {
    display: flex;
    padding: 10px 20px;
}

.content .edit-add .copy-address button {
    min-width: 10% !important;
}

.content .edit-add .copy-address p {
    font-size: 18px;
}

.content .edit-add .copy-address .view-data-title {
    font-weight: 600;
}

.content .edit-add .copy-address a {
    font-weight: 400;
    color: #1c6da8;
}

.content .edit-add .copy-address a:hover {
    text-decoration: underline;
}