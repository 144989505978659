.user-view-btn {
  display: flex;
  justify-content: center;
  padding: 4px;
}

/* ========== Custom Styles Burn Modal =========== */

.burn-modal .burn-modal-title p {
  color: #fff !important;
  font-size: 24px;
  font-weight: 500
}
.burn-modal .burn-modal-line hr {
  border-top: 1px solid rgb(255, 255, 255, 1) !important;
}
.burn-modal .modal-body .validator-form {
  padding: 20px 0px 60px;
}

.burn-modal .modal-body .text-field .MuiInputBase-input,
.burn-modal .modal-body .text-field .MuiFormLabel-root {
  color: #fff !important;
}

.burn-modal .modal-body .text-field .MuiInput-underline:before {
  border-bottom: 1px solid #ffffff;
}
.burn-modal .modal-body .text-field .MuiInput-underline:after {
  border-bottom: 1px solid #ffffff;
}

.burn-modal .modal-body .validator-form .burn-btn-div {
  display: flex;
  justify-content: center;
}

.burn-modal .modal-body .validator-form .burn-btn {
  width: 35%;
  color: #ffffff;
  border-radius: 10px;
  background-color: #1c6da8;
}